// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatureCard-module--root--pXa-D";
export var root__long = "PlasmicFeatureCard-module--root__long--Z3-XW";
export var box__w8FKs = "PlasmicFeatureCard-module--box__w8FKs--URzUD";
export var box__s5TPn = "PlasmicFeatureCard-module--box__s5TPn--27JYR";
export var svg___267W = "PlasmicFeatureCard-module--svg___267W--1rXOb";
export var box__qntkl = "PlasmicFeatureCard-module--box__qntkl--3-off";
export var box__long__qntklQCppr = "PlasmicFeatureCard-module--box__long__qntklQCppr--17fmV";
export var slotTitle = "PlasmicFeatureCard-module--slotTitle--ikjab";
export var slotDescription = "PlasmicFeatureCard-module--slotDescription--qbOZz";
export var slotDescription__long = "PlasmicFeatureCard-module--slotDescription__long--2x1ry";
export var box___26EQh = "PlasmicFeatureCard-module--box___26EQh--2ZNEB";
export var box__long___26EQhqCppr = "PlasmicFeatureCard-module--box__long___26EQhqCppr--1feQp";
export var svg__ml7Ah = "PlasmicFeatureCard-module--svg__ml7Ah--9EW1z";