// extracted by mini-css-extract-plugin
export var root = "PlasmicTopSection-module--root--8bAT5";
export var box__fyRrg = "PlasmicTopSection-module--box__fyRrg--I3oTd";
export var box__xq0HT = "PlasmicTopSection-module--box__xq0HT--1VQMI";
export var box__bLqTf = "PlasmicTopSection-module--box__bLqTf--2uDB-";
export var box__bkWnA = "PlasmicTopSection-module--box__bkWnA--2_A-m";
export var box__et5Rs = "PlasmicTopSection-module--box__et5Rs--2XXHQ";
export var box___907Y = "PlasmicTopSection-module--box___907Y--mgJC2";
export var box__kz5WX = "PlasmicTopSection-module--box__kz5WX--1zpDC";
export var linkButton__blOgM = "PlasmicTopSection-module--linkButton__blOgM--94be7";
export var linkButton__smFWo = "PlasmicTopSection-module--linkButton__smFWo--28mgw";
export var box__gSxU = "PlasmicTopSection-module--box__gSxU--1TDGC";
export var img = "PlasmicTopSection-module--img--22FGd";